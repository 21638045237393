import { gql } from "apollo-boost";

export const QUERY_ROUTE_LOCATION = gql`
query RouteLocationGroups(
  $where: RouteLocationGroupWhereInput
  $orderBy: OrderByInput
  $skip: Int
  $limit: Int
) {
  routeLocationGroups(
    where: $where
    orderBy: $orderBy
    skip: $skip
    limit: $limit
  ) {
    total
    data {
      _id
      branch_id {
        branch_name
      }
    }
  }
}
`;

export const CREATE_REGISTER_DRIVERS = gql`
  mutation CreateRegisterDriverPartner($data: RegisterDriverPartnerInput!) {
    createRegisterDriverPartner(data: $data) {
      _id
    }
  }
`;
