import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { aws_url_files } from "../../helper";
import filesCondition from "../../img/ans001.jpg";
export default function ReadFiles({ reloaddata, onSuccess }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn  btn-sm"
        style={{ marginTop: -16 }}
        onClick={() => setShow(true)}
      >
        ຍອດຮັບເງື່ອນໄຂໃນການປະມູນລົດ <b className="text-info">ອ່ານເງື່ອນໄຂ</b>
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="xl"
      >
        <Modal.Header>
         <b className="text-center fs-4 text-black"> ເງື່ອນໄຂໃນການປະມູນລົດເຂົ້າຮ່ວມ</b>
            <button
            className="btn btn-danger btn-lg float-right"
            onClick={() => handleClose()}
          >
            <i className="icon-x" style={{ marginRight: 3 }} />
            ປິດ
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <iframe
              src={aws_url_files}
              width="100%"
              height="700"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-block btn-lg"
            onClick={() => handleClose()}
          >
            <i className="icon-x" style={{ marginRight: 3 }} />
            ປິດ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
