import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import logo from "../../img/logo-app.png";
import "./util/index.css";
import male from "../../img/males.png";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CREATE_REGISTER_DRIVERS, QUERY_ROUTE_LOCATION } from "./apollo";
import {
  loadingData,
  messageSuccess,
  messageWarning,
  valiDate,
  aws_url_files,
} from "../../helper";
import { v4 as uuidv4 } from "uuid";
import { s3Client } from "../../helper/s3Client";
import ListRouteLocation from "../../helper/components/ListRoutelocation";
import ReadFiles from "./readfile";
export default function RegisterDriverPartner({ history }) {
  const [createRegister, { loading }] = useMutation(CREATE_REGISTER_DRIVERS);
  const [routeLocation, setListRouteLocation] = useState({});
  const [comFirmCondition, setComFirmCondition] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [closeFile, setCloseFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [file, setFile] = useState("");
  const getId = routeLocation?._id;
  const [fetchData, { data: setData, loading: getLoading }] = useLazyQuery(
    QUERY_ROUTE_LOCATION,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    fetchData({
      variables: {
        where: {
          locationID: String(getId),
        },
      },
    });
  }, [getId]);
  const handleUpload = async (event) => {
    const imageName = uuidv4() + "." + event.target.files[0].type.split("/")[1];
    const _file = event.target.files[0];
    setFile(_file);
    const res = await s3Client.uploadFile(_file, imageName);
    if (res?.location) {
      setFile(_file);
      setImageName(imageName);
      messageSuccess("ອັບໂຫຼດຮູບພາບສຳເລັດແລ້ວ");
    } else {
      setFile(null);
      setImageName("");
      messageWarning("ການອັບໂຫຼດຮູບພາບບໍ່ສຳເລັດ");
    }
  };
  // filse
  const subMitFiles = async (event) => {
    const _imageFile =
      uuidv4() + "." + event.target.files[0].type.split("/")[1];
    const _file = event.target.files[0];
    setCloseFile(_file);
    const res = await s3Client.uploadFile(_file, _imageFile);
    if (res?.location) {
      setCloseFile(_file);
      setImageFile(_imageFile);
    } else {
      setCloseFile(null);
      setImageFile("");
      messageWarning("ອັບໂຫຼດໄຟຣ໌ບໍ່ສຳເລັດ");
    }
  };
  return (
    <div
      className="justify-content-md-center p-1"
      style={{ paddingBottom: 100, marginTop: 10 }}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          note: "",
          phoneNumber: "",
          vehicleDetails: "",
          note: "",
          gender: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.lastName) {
            errors.lastName = "ກະລູນາປ້ອນນາມສະກຸມ";
          }
          if (!values.note) {
            errors.note = "ກະລູນາປ້ອນປະສົບການ ຫຼື ຜົນງານ";
          }
          if (!values.vehicleDetails)
            errors.vehicleDetails = "ກະລູນາປ້ອນລາຍລະອຽດລົດ";
          if (!values.firstName) errors.firstName = "ກະລູນາປ້ອນຊື່";
          if (!values?.gender) errors.gender = "ກະລູນາເລືອກເພດ";
          if (!imageFile) errors.files = "ກະລູນາເລືອກໄຟລ໌";
          if (!values.phoneNumber) errors.phoneNumber = "ກະລູນາປ້ອນເບີໂທ";
          if (
            values?.phoneNumber &&
            String(values?.phoneNumber)?.length !== 7 &&
            String(values?.phoneNumber)?.length !== 8
          ) {
            errors.phoneNumber =
              "ເບີໂທຕ້ອງບໍຕ່ຳກວ່າ 7 ໂຕເລກ ແລະ ສູງສຸດບໍເກີນ 8 ໂຕເລກ, ທ່ານປ້ອນແມ່ນ " +
              String(values?.phoneNumber)?.length +
              " ຕົວເລກ";
          }
          if (!routeLocation?._id) errors.routeID = "ກະລູນາເລືອກສາຍລົດ";
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            let _created = await createRegister({
              variables: {
                data: {
                  firstName: String(values?.firstName),
                  lastName: String(values?.lastName),
                  note: String(values?.note),
                  gender: String(values?.gender),
                  routeID: parseInt(routeLocation?._id),
                  profileImage: String((values.profileImage = imageName)),
                  files: String((values.files = imageFile)),
                  phoneNumber: parseInt(values?.phoneNumber),
                  vehicleDetails: String(values?.vehicleDetails),
                },
              },
            });
            if (_created) {
              messageSuccess("ສົ່ງຟອມສະໝັກສຳເລັດແລ້ວ");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          } catch (error) {
            messageWarning("ສົ່ງຟອມສະໝັກຜິດພາດ");
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card mt-1">
                  <div className="card-header">
                    <img src={logo} alt="logo" className="img-fluid" />
                  </div>
                  <div className="card-body">
                    <center>
                      <h2>ຟອມສະໝັກລົດຮ່ວມ</h2>
                    </center>
                    <hr />
                    <form className="form-horizontal">
                      <div className="form-group">
                        <label className="control-label">
                          ອັບໂຫຼດຮູບໂປຣໄຟລ໌
                        </label>
                        <div style={{ textAlign: "center" }} for="file-upload">
                          <input
                            type="file"
                            id="file-upload"
                            className="bg-danger"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={handleUpload}
                            hidden
                          />
                          <label
                            htmlFor="file-upload"
                            className="long-border long "
                          >
                            {file ? (
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                style={{ width: "170px", height: "180" }}
                                className="long"
                              />
                            ) : (
                              <img
                                src={male}
                                style={{ width: "170px", height: "180px" }}
                              />
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">
                              ຊື່ {valiDate()}
                            </label>
                            <div className="input-group mb-1">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="ປ້ອນຊື່ "
                                name="firstName"
                                required="required"
                                onChange={handleChange}
                                value={values.firstName}
                              />
                            </div>
                            <i className="text-danger mt-1">
                              {errors.firstName}
                            </i>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">
                              ນາມສະກຸມ{valiDate()}
                            </label>
                            <div className="input-group mb-1">
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="ປ້ອນນາມສະກຸມ "
                                name="lastName"
                                required="required"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                              />
                            </div>
                            <i className="text-danger mt-1">
                              {errors.lastName}
                            </i>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">ເພດ{valiDate()}</label>
                        <select
                          className="form-control form-control-lg mb-1"
                          name="gender"
                          onChange={handleChange}
                          value={values.gender}
                        >
                          <option value="">ເລືອກເພດ</option>
                          <option value="MALE">ຊາຍ</option>
                          <option value="FEMALE">ຍິງ</option>
                          <option value="OTHER">ບໍລະບຸ</option>
                        </select>
                        <i className="text-danger mt-1">{errors.gender}</i>
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          ເບີໂທ{valiDate()}
                        </label>
                        <div className="input-group mb-1">
                          <div class="input-group-prepend">
                            <span
                              className="input-group-text form-control-lg"
                              style={{
                                backgroundColor: "#ebe4e6",
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8,
                              }}
                            >
                              +856 20
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            placeholder="7678xxxx "
                            name="phoneNumber"
                            required="required"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            maxLength={8}
                          />
                        </div>
                        <i className="text-danger mt-1">{errors.phoneNumber}</i>
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          ເລືອກສາຍລົດ{valiDate()}
                        </label>
                        <ListRouteLocation
                          getData={(data) => {
                            setListRouteLocation(data);
                          }}
                          defaultValue={routeLocation?.title ?? ""}
                          size="lg"
                        />
                        <div className="text-danger mt-1">{errors.routeID}</div>
                      </div>
                      <div
                        className="form-group card p-1"
                        style={{ backgroundColor: "#fafafafa" }}
                        hidden={!routeLocation?.title}
                      >
                        <h3>ສາຂາ ນອນໃນສາຍ {routeLocation?.title}</h3>
                        {setData?.routeLocationGroups?.data?.map(
                          (data, index) => (
                            <>
                              <label className="control-label">
                                {index + 1}{" "}
                                {data?.branch_id?.branch_name
                                  ? data?.branch_id?.branch_name
                                  : ""}
                              </label>
                            </>
                          )
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          ລາຍລະອຽດຂໍ້ມູນລົດ{" "}
                        </label>
                        <textarea
                          className="form-control form-control-lg mb-1"
                          rows={3}
                          placeholder="ປ້ອນລາຍລະອຽດຂໍ້ມູນລົດ"
                          name="vehicleDetails"
                          required="required"
                          onChange={handleChange}
                          value={values.vehicleDetails}
                        ></textarea>
                        <i className="text-danger mt-1">
                          {errors.vehicleDetails}
                        </i>
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          ລາຍລະອຽດ (ຜົນງານ ແລະ ປະສົບການ){" "}
                        </label>
                        <textarea
                          className="form-control form-control-lg mb-1"
                          rows={5}
                          placeholder="ປ້ອນລາຍລະອຽດ"
                          name="note"
                          required="required"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.note}
                        ></textarea>
                        <i className="text-danger mt-1">{errors.note}</i>
                      </div>
                      <div className="form-group mb-3">
                        <label>ເລືອກໄຟລ໌ເອກະສານ{valiDate()}</label>
                        <input
                          type="file"
                          className="form-control form-control-lg mb-1"
                          name="files"
                          required="required"
                          accept="image/*,.pdf"
                          onChange={subMitFiles}
                          placeholder="ເລືອກເອກະສານ"
                        />
                        <i className="text-danger mt-1">{errors.files}</i>
                      </div>
                      <div className="form-check my-4">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="accept"
                          style={{ width: 20, height: 20 }}
                          onChange={(e) => {
                            setComFirmCondition(e.target.checked);
                          }}
                        />
                        ຍອມຮັບເງື່ອນໄຂໃນການປະມູນລົດ{" "}
                        <label className="form-check-label mt-1" for="accept">
                          <span>
                            <a
                              style={{ color: "blue" }}
                              href={aws_url_files}
                              target="_bank"
                            >
                              ອ່ານເງື່ອນໄຂ
                            </a>
                          </span>
                        </label>
                      </div>

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-block  btn-lg btn-primary"
                        disabled={!comFirmCondition}
                      >
                        {loading ? (
                          loadingData
                        ) : (
                          <>
                            <i className="fa-solid fa-paper-plane me-1" />{" "}
                           ສົ່ງຟອມ
                          </>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
